import React from 'react';
import { Route } from 'react-router-dom';

import AddCode from '../containers/AddCode';
import GenerateCodes from '../containers/GenerateCodes';
import GeneratePromoLink from '../containers/GeneratePromoLink';
import CreateOrEditPromotion from '../containers/CreateOrEditPromotion';
import EditAdminNote from '../containers/EditAdminNote';
import EditPromotionCode from '../containers/EditPromotionCode';
import Promotions from '../containers/promotions/Promotions';
import ViewPromotion from '../containers/promotions/ViewPromotion';
import ViewPromotionDetails from '../containers/promotions/ViewPromotionDetails';

const PromotionRoutes = () => {
  return (
    <>
      <Route exact={true} path="/promotions" component={Promotions} />
      <Route
        exact={true}
        path="/promotion/:id/view"
        component={ViewPromotion}
      />
      <Route
        exact={true}
        path="/promotion/:id/details"
        component={ViewPromotionDetails}
      />
      <Route
        exact={true}
        path="/promotion/:id/addcode"
        render={props => {
          return <AddCode promotion={props.location.state.promotion} />;
        }}
      />
      <Route
        exact={true}
        path="/promotion/:id/generatecodes"
        component={GenerateCodes}
      />
      <Route
        exact={true}
        path="/promotion/create"
        render={props => (
          <CreateOrEditPromotion method="create" history={props.history} />
        )}
      />
      <Route
        exact={true}
        path="/promotion/:id/edit"
        render={props => (
          <CreateOrEditPromotion
            method="edit"
            id={props.match.params.id}
            history={props.history}
          />
        )}
      />
      <Route
        exact={true}
        path="/promotion/:id/editNote"
        component={EditAdminNote}
      />
      <Route
        exact={true}
        path="/promotion_code/:id/edit"
        component={EditPromotionCode}
      />
      <Route
        exact={true}
        path="/promotion/:id/generateLink"
        render={props => {
          return (
            <GeneratePromoLink
              code={props.location.state.code}
              id={props.match.params.id}
              title={props.location.state.title}
              items={props.location.state.items}
            />
          );
        }}
      />
    </>
  );
};

export default PromotionRoutes;
