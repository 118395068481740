import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  Space,
  Select,
  Row,
  Col,
  InputNumber,
  Input,
  Button,
  notification
} from 'antd';
import { DeleteTwoTone } from '@ant-design/icons';
import { debounce } from 'lodash';
import { useQuery } from '@apollo/client';
import { GET_ITEMS_LIST_QUERY } from '../../containers/items/queries/GetItemsListQueries';
import * as API from '../../API';

import './BundleItems.css';

const { Option } = Select;

export default function BundleItems({ bundleId }) {
  const [allItems, setAllItems] = useState(null);
  const [activeBundleItems, setActiveBundleItems] = useState([]);
  const [activeItems, setActiveItems] = useState([]);
  const [reloadItems, setReloadItems] = useState(false);
  const [cartLink, setCartLink] = useState('');
  const [showLink, setShowLink] = useState(false);

  const { data: itemsListData } = useQuery(GET_ITEMS_LIST_QUERY);

  useEffect(() => {
    const getBundleItems = async () => {
      const bundleItems = await API.bundleItemBinding.where({
        filter: {
          bundle: bundleId
        },
        options: {
          include: 'item'
        }
      });

      setActiveBundleItems(bundleItems);
      setActiveItems(bundleItems.included);
      if (itemsListData && itemsListData.itemsList?.length > 0) {
        setAllItems(itemsListData.itemsList);
      }
    };
    getBundleItems();
  }, [bundleId, itemsListData, reloadItems]);

  const handleAddItemToBundle = async itemId => {
    await API.bundleItemBinding.create({
      attributes: {},
      relationships: {
        item: {
          data: {
            type: 'item',
            id: itemId
          }
        },
        bundle: {
          data: {
            type: 'bundle',
            id: bundleId
          }
        }
      }
    });
    setCartLink('');
    setShowLink(false);
    setReloadItems(!reloadItems);
  };

  const handleUpdateBundleItem = debounce(
    async (value, bundleItemId, field) => {
      await API.bundleItemBinding.update({
        id: bundleItemId,
        attributes: {
          [field]: value
        }
      });
      setReloadItems(!reloadItems);
    }
  );

  const handleDeleteItemFromBundle = async bundleItemId => {
    await API.bundleItemBinding.delete({
      id: bundleItemId
    });
    setCartLink('');
    setShowLink(false);
    setReloadItems(!reloadItems);
  };

  const itemFinder = itemId => activeItems.find(item => item.id === itemId);

  const generateCartLink = () => {
    const skus = activeBundleItems.data
      .map(bundleItem => {
        const matchingItem = itemFinder(bundleItem.relationships.item.data.id);
        return matchingItem ? matchingItem.attributes.sku : '';
      })
      .filter(Boolean);

    const link = `https://www.hippoed.com/cart?cartItems=${skus.join(',')}`;
    setCartLink(link);
    setShowLink(true);
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(cartLink);
    notification.success({
      message: 'Link copied to clipboard'
    });
  };

  return (
    <>
      <Space direction="vertical">
        <Row gutter={[16, 4]}>
          <div>
            <Select
              className="bundleItems__select-min-width"
              placeholder="+ Add Item To Bundle"
              onChange={item => handleAddItemToBundle(item)}
            >
              {allItems &&
                allItems.map(item => (
                  <Option key={item.id} value={item.id}>
                    {item.title} <small>({item.sku})</small>
                  </Option>
                ))}
            </Select>
          </div>
        </Row>
        <Row className="bundleItems__column-labels">
          <Col span={8}>
            <b>Item</b>
          </Col>
          <Col span={3} className="bundleItems__discount-column">
            <b>Discount Amt($)</b>
          </Col>
          <Col span={3} className="bundleItems__discount-column">
            <b>Discount (%)</b>
          </Col>
          <Col span={10}>
            <b>Description</b>
          </Col>
        </Row>
        <hr />
        {activeBundleItems &&
          activeBundleItems.data &&
          activeBundleItems.data.map(bundleItem => {
            const matchingItem = itemFinder(
              bundleItem.relationships.item.data.id
            );
            return (
              <Row
                key={bundleItem.id}
                className="bundleItems__column-padded-md"
              >
                <Col span={8}>
                  <DeleteTwoTone
                    className="bundleItems__icon-margin"
                    onClick={() => handleDeleteItemFromBundle(bundleItem.id)}
                    twoToneColor="red"
                    size="large"
                  />
                  <Link to={`/item/${matchingItem && matchingItem.id}`}>
                    {matchingItem ? matchingItem.attributes.title : ''}
                  </Link>
                </Col>
                <Col span={3}>
                  <InputNumber
                    defaultValue={bundleItem.attributes.discountAmount}
                    onChange={value =>
                      handleUpdateBundleItem(
                        value,
                        bundleItem.id,
                        'discountAmount'
                      )
                    }
                  />
                </Col>
                <Col span={3}>
                  <InputNumber
                    defaultValue={
                      bundleItem.attributes.discountPercentage * 100
                    }
                    onChange={value =>
                      handleUpdateBundleItem(
                        value / 100,
                        bundleItem.id,
                        'discountPercentage'
                      )
                    }
                  />
                </Col>
                <Col span={10}>
                  <Input
                    defaultValue={bundleItem.attributes.description}
                    type="text"
                    onChange={event =>
                      handleUpdateBundleItem(
                        event.target.value,
                        bundleItem.id,
                        'description'
                      )
                    }
                  />
                </Col>
              </Row>
            );
          })}
        <span>
          <em>
            <strong>*note: </strong>If both a discount amount ($) and a discount
            percentage are set, then discount amount will always take priority
            and the percentage will be ignored.
          </em>
        </span>
        <hr />
        <Button type="primary" onClick={generateCartLink}>
          Generate Cart Link
        </Button>
        {showLink && (
          <div>
            <div className="bundleItems__cart-link" onClick={copyToClipboard}>
              {cartLink}
            </div>
            <i>Click anywhere within box to copy</i>
          </div>
        )}
      </Space>
    </>
  );
}
