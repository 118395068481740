import _ from 'underscore';
import React from 'react';
import { Link } from 'react-router-dom';

import { Breadcrumb, Button, Spin, Table, Tag, notification } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';

import moment from 'moment';
import './Promotions.css';

import { getTokenFromCookie } from '../../utils/cookie';

export default class ViewPromotion extends React.Component {
  state = {
    promotion: null,
    showingSummary: false
  };

  async fetchPromotion() {
    fetch(
      `${process.env.REACT_APP_JSONAPI_SERVER}/api/promotion/${this.props.match.params.id}`,
      {
        method: 'GET',
        credentials: 'same-origin',
        headers: {
          Authorization: `Bearer ${getTokenFromCookie()}`
        }
      }
    )
      .then(response => {
        response.json().then(promotion => this.setState({ promotion }));
      })
      .catch(err => {
        window.setError(err);
      });
  }

  componentDidMount() {
    this.fetchPromotion();
  }

  async deleteCode(code) {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_JSONAPI_SERVER}/api/promotion-code/${code}`,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${getTokenFromCookie()}`
          },
          method: 'DELETE',
          credentials: 'same-origin'
        }
      );
      if (response.ok) {
        this.fetchPromotion();
      } else {
        const error = await response.json();
        notification.error({
          message: `Error deleting ${code}`,
          description: error.message
        });
      }
    } catch (err) {
      notification.error({
        message: `Error deleting ${code}`,
        description: err.message
      });
    }
  }

  render() {
    if (!this.state.promotion) {
      return <Spin />;
    }

    const start =
      this.state.promotion && this.state.promotion.promotion.startedAt;
    const end = this.state.promotion && this.state.promotion.promotion.endedAt;
    const expirationDate = this.state.promotion?.promotion?.subscriptionEndedAt;

    const promotionCodes = this.state.promotion
      ? this.state.promotion.promotionCodes.map(code => {
          return {
            ...code,
            items: this.state.promotion.promotion.PromotionItemBindings,
            title: this.state.promotion.promotion.title,
            key: code.id,
            used:
              this.state.promotion.orders.find(
                order => order.promotionCode === code.code
              ) || false
          };
        })
      : [];
    const oneTimeCodes = _.pluck(
      _.where(promotionCodes, { isLimitedUse: true }),
      'code'
    );
    const unlimitedCodes = _.pluck(
      _.where(promotionCodes, { isLimitedUse: false }),
      'code'
    );

    return (
      <div style={{ marginBottom: 20 }}>
        <Breadcrumb tag="nav" style={{ padding: '20px 0' }}>
          <Breadcrumb.Item>
            <Link to="/dashboard">Hippo Admin</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to="/promotions">Promotions</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item active="true">
            <Link to={window.location.pathname}>
              {this.state.promotion && this.state.promotion.promotion.title}
            </Link>
          </Breadcrumb.Item>
        </Breadcrumb>

        <h3>
          {this.state.promotion.promotion.title}
          <Link
            style={{ fontSize: 14, fontWeight: 500 }}
            to={`/promotion/${this.state.promotion.promotion.id}/edit`}
          >
            &nbsp;edit
          </Link>
        </h3>

        <h5>Promotion Details</h5>
        <table>
          <tbody>
            <tr>
              <td className="order-table-left-column">
                <strong>Title</strong>
              </td>
              <td>{this.state.promotion.promotion.title}</td>
            </tr>
            <tr>
              <td className="order-table-left-column">
                <strong>Public note</strong>
              </td>
              <td>{this.state.promotion.promotion.publicNote || '<none>'}</td>
            </tr>
            <tr>
              <td className="order-table-left-column">
                <strong>Product</strong>
              </td>
              <td>
                {(this.state.promotion.promotion.Product &&
                  this.state.promotion.promotion.Product.title) ||
                  'Multiple Products'}
              </td>
            </tr>
            <tr>
              <td className="order-table-left-column">
                <strong>Discounted items receive CME?</strong>
              </td>
              <td>{this.state.promotion.promotion.hasCme ? 'YES' : 'NO'}</td>
            </tr>
            <tr>
              <td className="order-table-left-column">
                <strong>One-time use?</strong>
              </td>
              <td>{this.state.promotion.promotion.isLimited ? 'YES' : 'NO'}</td>
            </tr>
            <tr>
              <td className="order-table-left-column">
                <strong>Item Limit per Transaction</strong>
              </td>
              <td>
                {this.state.promotion.promotion.itemLimitPerTransaction === 0
                  ? 'Unlimited'
                  : this.state.promotion.promotion.itemLimitPerTransaction}
              </td>
            </tr>
            <tr>
              <td className="order-table-left-column">
                <strong>Active period</strong>
              </td>
              <td>
                {!start && !end ? 'No dates specified' : null}
                {start && end ? (
                  <span>
                    {moment(start).format('MMMM Do YYYY, h:mm a')} to <br />
                    {moment(end).format('MMMM Do YYYY, h:mm a')}
                  </span>
                ) : null}
                {start && !end ? (
                  <span>
                    From {moment(start).format('MMMM Do YYYY, h:mm a')}
                  </span>
                ) : null}
                {!start && end ? (
                  <span>
                    Ending {moment(end).format('MMMM Do YYYY, h:mm a')}
                  </span>
                ) : null}
              </td>
            </tr>
            <tr>
              <td className="order-table-left-column">
                <strong>Expires on</strong>
              </td>
              <td>
                {expirationDate ? (
                  <span>
                    {moment(expirationDate).format('MMMM Do YYYY, h:mm a')}
                  </span>
                ) : (
                  'No expiration'
                )}
              </td>
            </tr>
            <tr>
              <td className="order-table-left-column">
                <strong>Promotion</strong>
              </td>
              <td>
                {this.state.promotion.promotion.discountAmount ? (
                  <span>${this.state.promotion.promotion.discountAmount}</span>
                ) : null}
                {this.state.promotion.promotion.discountPercentage ? (
                  <span>
                    {this.state.promotion.promotion.discountPercentage}%
                  </span>
                ) : null}
              </td>
            </tr>
            <tr>
              <td className="order-table-left-column">
                <strong>Length</strong>
              </td>
              <td>
                <span>
                  {this.state.promotion.promotion.promotionLength || '<none>'}
                  &nbsp;
                  {this.state.promotion.promotion.promotionLength
                    ? 'months'
                    : ''}
                </span>
              </td>
            </tr>
            <tr>
              <td className="order-table-left-column">
                <strong>Administrative notes</strong>
              </td>
              <td>
                <span>
                  {this.state.promotion.promotion.note || '<none>'}&nbsp;
                  <Link
                    to={`/promotion/${this.state.promotion.promotion.id}/editNote`}
                  >
                    Edit
                  </Link>
                </span>
              </td>
            </tr>
            <tr>
              {this.state.promotion.promotion.bannerTitle && (
                <>
                  <td className="order-table-left-column">
                    <strong>Banner Title</strong>
                  </td>
                  <td>
                    <span>{this.state.promotion.promotion.bannerTitle}</span>
                  </td>
                </>
              )}
            </tr>
            <tr>
              <td className="order-table-left-column">
                <strong>Items</strong>
              </td>
              <td>
                <ul style={{ listStyle: 'none', padding: 0, marginTop: 10 }}>
                  {this.state.promotion.promotion.PromotionItemBindings.map(
                    (binding, index) => {
                      return <li key={index}>{binding.Item.sku}</li>;
                    }
                  )}
                </ul>
              </td>
            </tr>
          </tbody>
        </table>

        <h5>Promotion Use</h5>
        <ul>
          <li>
            <p>
              Orders: {this.state.promotion.orders.length} orders{' '}
              <Link
                to={`/promotion/${this.state.promotion.promotion.id}/details`}
              >
                Details
              </Link>
            </p>
          </li>
        </ul>

        <h5>Promotion Codes</h5>
        <Button.Group style={{ margin: '10px 0' }}>
          {this.state.showingSummary ? (
            <Button onClick={() => this.setState({ showingSummary: false })}>
              Hide summary
            </Button>
          ) : (
            <Button onClick={() => this.setState({ showingSummary: true })}>
              Show summary
            </Button>
          )}
          <Button>
            <Link
              to={{
                pathname: `/promotion/${this.props.match.params.id}/addcode`,
                state: { promotion: this.state.promotion.promotion }
              }}
            >
              Add code
            </Link>
          </Button>
          <Button
            onClick={() =>
              window.open(
                `/promotion/${this.props.match.params.id}/generatecodes`,
                '_blank'
              )
            }
          >
            Generate codes
          </Button>
        </Button.Group>

        {this.state.showingSummary ? (
          <div
            style={{
              padding: 8,
              marginBottom: 10,
              backgroundColor: 'rgb(212, 211, 124)',
              fontSize: 11
            }}
          >
            <p>
              <strong>One-time use:</strong>{' '}
              {oneTimeCodes.join(', ') || '<none>'}
            </p>
            <p>
              <strong>Unlimited use:</strong>{' '}
              {unlimitedCodes.join(', ') || '<none>'}
            </p>
          </div>
        ) : null}

        <Table
          style={{ backgroundColor: '#fff', margin: '10px 0 20px 0' }}
          pagination={true}
          columns={[
            {
              title: 'Code',
              key: 'code',
              render: code => {
                return <span>{code.code}</span>;
              }
            },
            {
              title: 'One-time use',
              key: 'one-time use',
              render: code => {
                return code.isLimitedUse ? (
                  <Tag color="green">Yes</Tag>
                ) : (
                  <Tag color="red">No</Tag>
                );
              }
            },
            {
              title: 'Used',
              key: 'used',
              render: code => {
                return <Tag>{code.used ? 'Used' : 'Not used'}</Tag>;
              }
            },
            {
              title: '',
              key: 'link',
              render: code => {
                return (
                  <Link
                    className="view-promotion__link-button"
                    to={{
                      pathname: `/promotion/${this.props.match.params.id}/generateLink`,
                      state: {
                        title: code.title,
                        code: code.code,
                        items: code.items
                      }
                    }}
                  >
                    get promo link
                  </Link>
                );
              }
            },
            {
              title: '',
              key: 'edit',
              render: code => {
                return (
                  <Button
                    onClick={() =>
                      window.open(`/promotion_code/${code.id}/edit`, '_blank')
                    }
                  >
                    edit
                  </Button>
                );
              }
            },
            {
              title: '',
              key: 'delete',
              render: code => {
                return (
                  <Button
                    type="danger"
                    icon={<DeleteOutlined className="account__delete-icon" />}
                    size="small"
                    title="Delete promo code"
                    onClick={() => this.deleteCode(code.code)}
                  />
                );
              }
            }
          ]}
          dataSource={promotionCodes}
        />

        <h5>Corresponding orders</h5>
        <Table
          style={{ backgroundColor: '#fff', margin: '20px 0' }}
          pagination={true}
          columns={[
            {
              title: 'Hippo ID',
              key: 'id',
              render: order => {
                return <Link to={'/orders/' + order.id}>{order.id}</Link>;
              }
            },
            {
              title: 'Account',
              key: 'account',
              render: order => {
                return order.Account ? (
                  order.Account.firstName + ' ' + order.Account.lastName
                ) : (
                  <Tag>None</Tag>
                );
              }
            },
            {
              title: 'Code',
              key: 'code',
              render: order => {
                return order.promotionCode;
              }
            },
            {
              title: 'Date',
              key: 'date',
              render: order => {
                return moment(order.createdAt).format('MMMM Do YYYY, h:mm a');
              }
            }
          ]}
          dataSource={this.state.promotion ? this.state.promotion.orders : []}
        />
      </div>
    );
  }
}
