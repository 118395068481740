import React, { useState } from 'react';
import {
  Alert,
  Button,
  Card,
  CardTitle,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row
} from 'reactstrap';
import { Link } from 'react-router-dom';
import { Breadcrumb } from 'antd';
import { getTokenFromCookie } from '../utils/cookie';

export default function AddCode({ promotion }) {
  const [code, setCode] = useState('');
  const [isLimitedUse, setIsLimitedUse] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [isValidCode, setIsValidCode] = useState(null);
  const [error, setError] = useState('');

  const handleCodeChange = e => {
    const newCode = e.target.value;

    if (newCode.length === 0) {
      setIsValidCode(null);
    } else if (newCode.length > 50) {
      return setIsValidCode(false);
    }

    const codeRegex = /[^A-Za-z0-9_.~-]$/;
    const isValidCode = !codeRegex.test(newCode);

    setIsValidCode(isValidCode);
    isValidCode && setCode(newCode);
  };

  async function addCode() {
    if (!code) {
      return alert('You must provide a code.');
    }

    const params = {
      promotionId: promotion.id,
      isLimitedUse
    };

    const response = await fetch(
      `${process.env.REACT_APP_JSONAPI_SERVER}/api/promotion-code/${code}`,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${getTokenFromCookie()}`
        },
        method: 'POST',
        credentials: 'same-origin',
        body: JSON.stringify(params)
      }
    );
    if (response.ok) {
      setShowSuccess(true);
      setTimeout(() => {
        setShowSuccess(false);
      }, 1500);
    } else {
      const error = await response.json();
      setError(error.message);
    }
  }

  if (!promotion) {
    return <div />;
  }

  return (
    <div>
      <Row>
        <Col>
          <Breadcrumb style={{ padding: '20px 0' }}>
            <Breadcrumb.Item>
              <Link to="/dashboard">Hippo Admin</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to="/promotions">Promotions</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to={`/promotion/${promotion.id}/view`}>
                {promotion.title}
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item active="true">
              <Link to={`/promotion/${promotion.id}/newcode`}>
                New promotion code
              </Link>
            </Breadcrumb.Item>
          </Breadcrumb>
        </Col>
      </Row>
      {showSuccess ? (
        <Row>
          <Col>
            <Alert color="success">Successfully created promotion code</Alert>
          </Col>
        </Row>
      ) : null}
      {error ? (
        <Row>
          <Col>
            <Alert color="danger">{error}</Alert>
          </Col>
        </Row>
      ) : null}
      <Row>
        <Col>
          <Card body={true} style={{ width: 400 }}>
            <CardTitle>Add promotion code</CardTitle>
            <div>
              <Form>
                <FormGroup>
                  <Label>Code (required)</Label>
                  <Input
                    bsSize="sm"
                    type="code"
                    name="code"
                    placeholder="New promotion code"
                    value={code}
                    onChange={handleCodeChange}
                    onFocus={() => setError('')}
                  />
                  {isValidCode === false && (
                    <p style={{ paddingTop: 12, color: '#c23c54' }}>
                      Please enter a valid code with 50 characters or less. Only
                      A-Z 0-9 - _ ~ . allowed.
                    </p>
                  )}
                  <p style={{ fontSize: 12, paddingTop: 12 }}>
                    This is the code the customer enters to receive the
                    promotion. Must be unique. Not case-sensitive.
                  </p>
                </FormGroup>
                <FormGroup check={true}>
                  <Label check={true}>
                    <Input
                      type="checkbox"
                      value={isLimitedUse}
                      onChange={e => setIsLimitedUse(e.target.checked)}
                    />
                    One-time use
                  </Label>
                </FormGroup>
              </Form>
            </div>
            <Button color="primary" onClick={addCode}>
              Create
            </Button>
            <Link to={`/promotion/${promotion.id}/view`}>Go back</Link>
          </Card>
        </Col>
      </Row>
    </div>
  );
}
