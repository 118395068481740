import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import AWSUploadInput from './AWSUploadInput';
import * as API from '../../API';

import './SingleImageInput.css';
import { DeleteFilled } from '@ant-design/icons';
import { Button } from 'antd';

export default function SingleImageInput({
  name,
  value,
  onChange,
  className,
  imgClassName,
  ...rest
}) {
  return (
    <div className={classNames('single-attachment-input', className)}>
      {value && (
        <Button
          className="single-attachment-input__preview-image-button"
          type="link"
          onClick={() => onChange && onChange(null)}
        >
          {onChange && <DeleteFilled />}
          <img
            className={classNames(
              'single-attachment-input__preview-image',
              imgClassName
            )}
            src={value.attributes ? value.attributes.url : value.url}
            alt={name}
          />
        </Button>
      )}
      {!value && (
        <AWSUploadInput
          name={name}
          accept="image/*"
          {...rest}
          onComplete={async fileList => {
            const result = await API.image.create({
              attributes: {
                url: fileList[0].url,
                createdAt: new Date(),
                updatedAt: new Date()
              },
              relationships: {}
            });
            const image = result.data;
            onChange && onChange(image);
          }}
        />
      )}
    </div>
  );
}

SingleImageInput.defaultProps = {
  value: null,
  className: null,
  onChange: null,
  rest: null
};

SingleImageInput.propTypes = {
  value: PropTypes.any,
  className: PropTypes.string,
  onChange: PropTypes.func,
  rest: PropTypes.any
};
